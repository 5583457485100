import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Hoi Bente
        </p>
        <img src='https://cms.dierenbescherming.nl/assets/common/eekhoorns07.jpg'></img>
      </header>
    </div>
  );
}

export default App;
